<template>
  <div class="vue-drag-select" @mousedown="onMouseDown">
    <slot :selectedItems="selectedItems" />
    <div v-show="mouseDown" class="vue-drag-select-box" :style="selectionBoxStyling" ref="selection"></div>
  </div>
</template>

<script>

function diffArray(array) {
  const newArray = array.concat();
  const map = new Map();
  newArray.forEach(v => map.set(v, map.has(v) ? map.get(v) + 1 : 1));
  return newArray.filter(v => map.get(v) === 1);
}
export default {
  name: "vue-drag-select",
  props: {
    selectorClass: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: "rgba(0, 162, 255, .4)",
    },
    selectedItems: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      default: "override"
    },
    items: {
      type: Array,
      required: true,
    },
    primary: { default: 'codigo' }
  },
  data() {
    var self = this;
    return {
      mouseDown: false,
      concat: false,
      startPoint: null,
      startData: [],
      lastSelected: [],
      endPoint: null,
      setValue: this.$utils.misc.debounce(function (v) {
        self.$emit("change", v);
      }, 5),
      //setValue: (v) => self.$emit("change", v)
    };
  },
  computed: {
    selectionBox() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) return {};
      const clientRect = this.$el.getBoundingClientRect();
      const scroll = this.getScroll();
      // Calculate position and dimensions of the selection box
      const left =
        Math.min(this.startPoint.x, this.endPoint.x) - clientRect.left;
      const top = Math.min(this.startPoint.y, this.endPoint.y) - clientRect.top;
      const width = Math.abs(this.startPoint.x - this.endPoint.x);
      const height = Math.abs(this.startPoint.y - this.endPoint.y);
      // Return the styles to be applied
      return {
        left,
        top,
        width,
        height,
      };
    },
    selectionBoxStyling() {
      // Only set styling when necessary
      if (!this.mouseDown || !this.startPoint || !this.endPoint) {
        return { background: this.color };
      }
      const { left, top, width, height } = this.selectionBox;
      // Return the styles to be applied
      return {
        background: this.color,
        left: `${left}px`,
        top: `${top}px`,
        width: `${width}px`,
        height: `${height}px`,
      };
    },
  },
  /*watch: {
    selectedItems(val) {
      this.$emit("change", val);
    },
  },*/
  methods: {
    getScroll() {
      // If we're on the server, default to 0,0
      if (typeof document === "undefined") {
        return {
          x: 0,
          y: 0,
        };
      }
      return {
        x:
          this.$el.scrollLeft ||
          document.body.scrollLeft ||
          document.documentElement.scrollLeft,
        y:
          this.$el.scrollTop ||
          document.body.scrollTop ||
          document.documentElement.scrollTop,
      };
    },
    onMouseDown(event) {
      // Ignore right clicks
      if (event.button === 2) return;
      // Check if shift is down
      this.concat = event.shiftKey || event.ctrlKey;
      // Register begin point
      this.mouseDown = true;

      const scroll = this.getScroll();
      this.startPoint = {
        x: event.pageX + scroll.x,
        y: event.pageY + scroll.y,
      };
      this.startData = this.selectedItems;

      // Start listening for mouse move and up events
      this.onMouseMove(event);
      window.addEventListener("mousemove", this.onMouseMove);
      window.addEventListener("mouseup", this.onMouseUp);
    },
    onMouseMove(event) {
      let self = this;
      // Update the end point position
      if (this.mouseDown) {
        const scroll = this.getScroll();
        this.endPoint = {
          x: event.pageX + scroll.x,
          y: event.pageY + scroll.y,
        };
        /*const children = this.$children.length
          ? this.$children
          : this.$el.children;*/
        const children = window.$(self.$el).find('.' + self.selectorClass)

        if (children.length) {
          /*let selected = Array.from(children).filter((item) => {
            return this.isItemSelected(item.$el || item);
          });*/
          let selected = Array.from(children.filter((i, el) => {
            return self.isItemSelected(el)
          }))

          // If shift was held during mousedown the new selection is added to the current. Otherwise the new selection
          // will be selected
          /*this.selectedItems = this.concat
            ? uniqueArray(this.selectedItems.concat(selected))
            : selected;*/
          selected = selected.map((el) => el.getAttribute("vkey"));
          selected = selected.sort()


          if (event.type == 'mousedown') {
            let arr;
            arr = self.toggleItemsByIds(selected);
            self.lastSelected = selected;
            this.setValue(arr);
          } else if (selected.length != self.lastSelected.length || !selected.every((v, i) => v === self.lastSelected[i])) {
            let arr;
            if (this.mode == 'toggle') {
              arr = self.toggleItemsByIds(selected);
            } else if (this.mode == 'add') {
              arr = self.addItemsByIds(selected)
            } else {
              arr = this.concat
                ? self.addItemsByIds(selected)
                : self.setItemsByIds(selected)
            }
            self.lastSelected = selected;
            this.setValue(arr);
          }

        }
      }
    },
    onMouseUp(event) {
      // Clean up event listeners
      window.removeEventListener("mousemove", this.onMouseMove);
      window.removeEventListener("mouseup", this.onMouseUp);
      // Reset state
      this.mouseDown = false;
      this.concat = false;
      this.startPoint = null;
      this.endPoint = null;
    },
    isItemSelected(el) {
      if (el.classList.contains(this.selectorClass)) {
        const boxA = {
          left: Math.min(this.startPoint.x, this.endPoint.x),
          top: Math.min(this.startPoint.y, this.endPoint.y),
          width: Math.abs(this.endPoint.x - this.startPoint.x),
          height: Math.abs(this.endPoint.y - this.startPoint.y)
        };
        const boxB = el.getBoundingClientRect();
        return !!(
          boxA.left <= boxB.left + boxB.width &&
          boxA.left + boxA.width >= boxB.left &&
          boxA.top <= boxB.top + boxB.height &&
          boxA.top + boxA.height >= boxB.top
        );
      }
      return false;
    },
    addItemsByIds(ids) {
      let self = this;
      //let objItems = self.items.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let objItems = new Map(self.items.map((obj) => [obj[this.primary], obj]));
      //let objSelectedItems = self.startData.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let objSelectedItems = new Map(self.startData.map((obj) => [obj[this.primary], obj]));
      let arr = self.startData.concat()
      ids.forEach(id => {
        if (!(objSelectedItems.has(id))) {
          arr.push(objItems.get(id));
        }
      })
      return arr;
    },
    setItemsByIds(ids) {
      let self = this;
      let objItems = self.items.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let objSelectedItems = self.selectedItems.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let arr = []
      ids.forEach(id => {
        if (!(objSelectedItems.has(id))) {
          arr.push(objItems.get(id));
        }
      })
      return arr;
    },
    toggleItemsByIds(ids) {
      let self = this;

      //let objItems = self.items.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let objItems = new Map(self.items.map((obj) => [obj[this.primary], obj]));
      //let objSelectedItems = self.startData.reduce((acc, v) => ({ ...acc, [v[self.primary]]: v }), {})
      let objSelectedItems = new Map(self.startData.map((obj) => [obj[this.primary], obj]));
      let arr = self.startData.concat()
      ids.forEach(id => {
        if (!(objSelectedItems.has(id))) {
          arr.push(objItems.get(id));
        } else {
          //window.console.log(id, self.startData.findIndex(it => it[self.primary] == id))
          arr.splice(self.startData.findIndex(it => it[self.primary] == id), 1)
        }
      })
      return arr;
    }
  },
  mounted() {
    /*this.$children.forEach((child) => {
      child.$on("click", (event) => {
        const included = this.selectedItems.find((item) => {
          return child.$el === item.$el;
        });
        if (included) {
          this.selectedItems = this.selectedItems.filter((item) => {
            return child.$el !== item.$el;
          });
        } else {
          this.selectedItems.push(child);
        }
      });
    });*/
  },
  beforeDestroy() {
    // Remove event listeners
    window.removeEventListener("mousemove", this.onMouseMove);
    window.removeEventListener("mouseup", this.onMouseUp);
    /*this.$children.forEach((child) => {
      child.$off("click");
    });*/
  },
};
</script>

<style>
.vue-drag-select {
  position: relative;
  user-select: none;
}

.vue-drag-select-box {
  position: absolute;
  z-index: 99;
}
</style>